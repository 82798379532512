'use client'

import Button from '@components/ui/button'

export default function GlobalError({
  error,
  reset,
}: {
  error: Error
  reset: () => void
}) {
  return (
    <html lang="en">
      <body className="text-text-white bg-black-800 min-h-screen flex justify-center items-center flex-col">
        <h2>Something went wrong!</h2>
        <Button onClick={() => reset()}>Try again</Button>
      </body>
    </html>
  )
}
